import React, { useState, useRef } from 'react';
import MenuContainer from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import { IconButton, makeStyles, Theme, useMediaQuery } from '@material-ui/core';

import AboutDialog from '../../../AboutDialog/AboutDialog';
import ConnectionOptionsDialog from '../../../ConnectionOptionsDialog/ConnectionOptionsDialog';
import DeviceSelectionDialog from '../../../DeviceSelectionDialog/DeviceSelectionDialog';
import { useAppState } from '../../../../state';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';
import { isSupported } from '@twilio/video-processors';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles({
  settingsButton: {
    width: '48px',
    height: '48px',
    background: '#D0E8D6',
    '&:hover': {
      background: '#C0D8C6',
    },
  },
});

export default function SettingsMenu({ mobileButtonClass }: { mobileButtonClass?: string }) {
  const classes = useStyles();
  const { roomType } = useAppState();
  const intl = useIntl();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [menuOpen, setMenuOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
  const [connectionSettingsOpen, setConnectionSettingsOpen] = useState(false);
  const { setIsBackgroundSelectionOpen } = useVideoContext();
  const { isAuthenticated } = useAuth0();

  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <IconButton ref={anchorRef} onClick={() => setMenuOpen(true)} className={classes.settingsButton}>
        <MoreIcon />
      </IconButton>

      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: isMobile ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'center',
        }}
      >
        {isAuthenticated && (
          <MenuItem onClick={() => setAboutOpen(true)}>
            <Typography variant="body1">About</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={() => setDeviceSettingsOpen(true)}>
          <Typography variant="body1">{intl.formatMessage({ id: 'audio_and_video_settings' })}</Typography>
        </MenuItem>
        {isSupported && (
          <MenuItem onClick={() => setIsBackgroundSelectionOpen(true)}>
            <Typography variant="body1">{intl.formatMessage({ id: 'backgrounds' })}</Typography>
          </MenuItem>
        )}
        {isAuthenticated && roomType !== 'peer-to-peer' && roomType !== 'go' && (
          <MenuItem onClick={() => setConnectionSettingsOpen(true)}>
            <Typography variant="body1">{intl.formatMessage({ id: 'connection_settings' })}</Typography>
          </MenuItem>
        )}
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
      <ConnectionOptionsDialog
        open={connectionSettingsOpen}
        onClose={() => {
          setConnectionSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
