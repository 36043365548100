import React, { ChangeEvent, FormEvent } from 'react';
import { makeStyles, TextField, Button, Theme, Typography } from '@material-ui/core';
import { useAppState } from '../../../state';
import { useIntl } from 'react-intl';
import moment from 'moment-timezone';
import { trackSelfDescribingEvent, Schema } from '@tourlane/tracking';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles((theme: Theme) => ({
  continueButton: {
    borderRadius: '100px',
    background: '#006D44',
    '&:hover': {
      background: '#006D44',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    textAlign: 'center',
  },
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#006D44',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#006D44',
      },
    },
    '& .MuiInputLabel-outlined': {
      '&.Mui-focused': {
        color: '#006D44',
      },
    },
  },
}));

interface RoomNameScreenProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ handleSubmit }: RoomNameScreenProps) {
  const intl = useIntl();
  const classes = useStyles();
  const { user, setUser, call } = useAppState();
  const { isAuthenticated } = useAuth0();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const u = user || {};
    setUser({ ...u, displayName: event.target.value });
    !isAuthenticated && trackSubmission();
  };

  const trackSubmission = () => {
    trackSelfDescribingEvent({
      event: {
        data: {
          action: 'submit',
          atom: 'confirm_name_form',
          label: 'enter_your_name',
          molecule: 'room-name',
        },
        schema: Schema.COMPONENT_EVENT,
      },
    });
  };

  const formattedDateTime = call?.dateAndTime && moment(call?.dateAndTime)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format(
      Intl.DateTimeFormat()
        .resolvedOptions()
        .locale.startsWith('en')
        ? 'DD MMM YYYY, hh:mm A'
        : 'DD.MM.YYYY, HH:mm'
    );

  const timezone = moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Typography variant="h5">
        {call?.destination && call?.agentName
          ? intl.formatMessage({ id: 'call_intro' }, { destination: call?.destination, agent: call?.agentName })
          : intl.formatMessage({ id: 'page_title' })}
      </Typography>

      {call?.dateAndTime && <Typography variant="h6">{`${formattedDateTime} ${timezone}`}</Typography>}

      <TextField
        className={classes.textField}
        id="input-user-name"
        variant="outlined"
        size="small"
        value={user?.displayName || ''}
        onChange={handleNameChange}
        label={intl.formatMessage({ id: 'enter_your_name' })}
      />
      <Button
        fullWidth
        variant="contained"
        type="submit"
        color="primary"
        disabled={!user?.displayName}
        className={classes.continueButton}
      >
        {intl.formatMessage({ id: 'button.continue' })}
      </Button>
    </form>
  );
}
