import React from 'react';
import MicIcon from '@material-ui/icons/MicNoneOutlined';
import MicOffIcon from '@material-ui/icons/MicOffOutlined';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { useIntl } from 'react-intl';

const useStyles = makeStyles({
  button: {
    width: '48px',
    height: '48px',
    background: '#D0E8D6',
    '&:hover': {
      background: '#C0D8C6',
    },
  },
});

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Tooltip
      title={!hasAudioTrack ?  intl.formatMessage({ id: 'no_audio' }) : isAudioEnabled ? intl.formatMessage({ id: 'mute' }) : intl.formatMessage({ id: 'unmute' })}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: !isAudioEnabled ? 'not-allowed' : 'pointer' }}
    >
      <span>
        <IconButton
          className={classes.button}
          onClick={toggleAudioEnabled}
          disabled={!hasAudioTrack || props.disabled}
          data-cy-audio-toggle
        >
          {isAudioEnabled ? <MicIcon aria-label="mic icon" /> : <MicOffIcon aria-label="mic off icon" />}
        </IconButton>
      </span>
    </Tooltip>
  );
}
