import React from 'react';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    borderRadius: '100px',
    borderColor: '#717972',
    color: "#BA1A1A",
  },
}));

const LogoutButton = () => {
  const classes = useStyles();
  const { logout } = useAuth0();

  const handleLogout = (event: React.MouseEvent) => {
    event.preventDefault();
    logout({ returnTo: window.location.origin });
  };

  return (
    <Button className={classes.button} variant="outlined" onClick={handleLogout}>
      Logout
    </Button>
  );
};

export default LogoutButton;
