import React, { useCallback, useRef } from 'react';
import VideocamIcon from '@material-ui/icons/VideocamOutlined';
import VideocamOffIcon from '@material-ui/icons/VideocamOffOutlined';
import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { useIntl } from 'react-intl';

const useStyles = makeStyles({
  button: {
    width: '48px',
    height: '48px',
    background: '#D0E8D6',
    '&:hover': {
      background: '#C0D8C6',
    },
  },
});

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();
  const classes = useStyles();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);
  const intl = useIntl();

  return (
    <Tooltip
      title={
        !hasVideoInputDevices
          ? intl.formatMessage({ id: 'no_video' })
          : isVideoEnabled
          ? intl.formatMessage({ id: 'stop_video' })
          : intl.formatMessage({ id: 'start_video' })
      }
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: !hasVideoInputDevices || props.disabled ? 'not-allowed' : 'pointer' }}
    >
      <span>
        <IconButton className={classes.button} onClick={toggleVideo} disabled={!hasVideoInputDevices || props.disabled}>
          {isVideoEnabled ? <VideocamIcon /> : <VideocamOffIcon />}
        </IconButton>
      </span>
    </Tooltip>
  );
}
