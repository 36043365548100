import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import LogoutButton from '../Buttons/LogoutButton/LogoutButton';
import BackgroundSelectionDialog from '../BackgroundSelectionDialog/BackgroundSelectionDialog';
import { useAuth0 } from '@auth0/auth0-react';
import Logo from '../../images/tourlane.png';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  innerContainer: {
    display: 'flex',
    width: '502px',
    minHeight: '539px',
    borderRadius: '16px',
    border: '1px solid #C0C9C0',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      border: 'none',
    },
  },
  tourlaneLogo: {
    top: 0,
    left: 0,
    width: '110px',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '61px',
    [theme.breakpoints.down('xs')]: {
      padding: '40px',
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
  header: {
    background: '#F0EEE6',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '62px',
    padding: '26px 40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img alt="Tourlane Logo" className={classes.tourlaneLogo} src={Logo}></img>
        {isAuthenticated && <LogoutButton />}
      </div>
      <div className={classes.innerContainer}>
        <div className={classes.content}>{props.children}</div>
      </div>
      <BackgroundSelectionDialog footerHeight={0} />
    </div>
  );
};

export default IntroContainer;
