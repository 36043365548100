import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import { isMobile } from '../../utils';
import Menu from './Menu/Menu';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Button, Hidden } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import { useIntl } from 'react-intl';
import { useAppState } from '../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
        alignItems: 'center',
        height: `${theme.mobileFooterHeight}px`,
        padding: '12px 16px',
      },
      '& p': {
        [theme.breakpoints.down('xs')]: {
          marginRight: 'auto',
        },
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    buttonsContainer: {
      display: 'flex',
      gap: '1em',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        gap: '0.5em',
      },
    },
    caption: {
      maxWidth: '200px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const intl = useIntl();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const { call } = useAppState();
  return (
    <>
      {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">{intl.formatMessage({ id: 'sharing_screen' })}</Typography>
          <Button onClick={() => toggleScreenShare()}>{intl.formatMessage({ id: 'button.stop_screen_share' })}</Button>
        </Grid>
      )}
      <footer className={classes.container}>
        {call && (
          <Hidden xsDown>
            <Typography variant="caption" className={classes.caption}>
              {intl.formatMessage({ id: 'call_intro' }, { destination: call?.destination, agent: call?.agentName })}
            </Typography>
          </Hidden>
        )}
        <div className={classes.buttonsContainer}>
          <Hidden smUp>
            <EndCallButton onlyIcon />
          </Hidden>
          <ToggleAudioButton disabled={isReconnecting} />
          <ToggleVideoButton disabled={isReconnecting} />
          {!isSharingScreen && !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
          <ToggleChatButton />
          <Menu />
        </div>
        <Hidden xsDown>
          <EndCallButton />
        </Hidden>
      </footer>
    </>
  );
}
