import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useIntl } from 'react-intl';
import PhoneIcon from '@material-ui/icons/CallEndOutlined';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    borderRadius: '100px',
    border: 'solid 1px #717972',
    color: '#BA1A1A',
  },
  iconButton: {
    width: '48px',
    height: '48px',
    border: 'solid 1px #717972',
    color: '#BA1A1A',
  }
}));

export default function EndCallButton({ onlyIcon }: { onlyIcon?: boolean }) {
  const intl = useIntl();
  const classes = useStyles();
  const { room } = useVideoContext();

  return onlyIcon ? (
    <IconButton onClick={() => room!.disconnect()} className={classes.iconButton} data-cy-disconnect>
      <PhoneIcon />
    </IconButton>
  ) : (
    <Button onClick={() => room!.disconnect()} className={classes.button} data-cy-disconnect startIcon={<PhoneIcon />}>
      {intl.formatMessage({ id: 'button.end_call' })}
    </Button>
  );
}
