import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, Typography, Grid, Button, Theme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';
import { trackSelfDescribingEvent, Schema } from '@tourlane/tracking';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
  },
  localPreviewContainer: {
    width: '100%',
  },
  joinButton: {
    borderRadius: '100px',
    background: '#006D44',
    '&:hover': {
      background: '#006D44',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1em',
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
}

export default function DeviceSelectionScreen({ name }: DeviceSelectionScreenProps) {
  const intl = useIntl();
  const classes = useStyles();
  const { getToken, isFetching, setUser, user } = useAppState();
  const { travelRequestUuid } = useParams<{ travelRequestUuid: string }>();
  const { connect: chatConnect } = useChatContext();
  const {
    connect: videoConnect,
    isAcquiringLocalTracks,
    isConnecting,
    setIsBackgroundSelectionOpen,
  } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const { isAuthenticated } = useAuth0();

  const handleJoin = () => {
    setIsBackgroundSelectionOpen(false);
    getToken(name, travelRequestUuid!, isAuthenticated).then(({ token, determinedUserIdentity }) => {
      videoConnect(token);
      chatConnect(token);
      setUser({ ...user, determinedUserIdentity });
    });
    !isAuthenticated && trackSubmission();
  };

  const trackSubmission = () => {
    trackSelfDescribingEvent({
      event: {
        data: {
          action: 'submit',
          atom: 'device_selection_form',
          label: 'join_now',
          molecule: 'device-selection',
        },
        schema: Schema.COMPONENT_EVENT,
      },
    });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            {intl.formatMessage({ id: 'joining_meeting' })}
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <div className={classes.container}>
      <Typography variant="h5">{intl.formatMessage({ id: 'header.join' })}</Typography>

      <div className={classes.localPreviewContainer}>
        <LocalVideoPreview identity={name} />
      </div>

      <div className={classes.buttonContainer}>
        <ToggleAudioButton disabled={disableButtons} />
        <ToggleVideoButton disabled={disableButtons} />
        <SettingsMenu />
      </div>

      <Button
        className={classes.joinButton}
        fullWidth
        variant="contained"
        color="primary"
        data-cy-join-now
        onClick={handleJoin}
        disabled={disableButtons}
      >
        {intl.formatMessage({ id: 'join_now' })}
      </Button>
    </div>
  );
}
