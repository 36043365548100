import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import Tooltip from '@material-ui/core/Tooltip';

import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useIntl } from 'react-intl';
import { IconButton } from '@material-ui/core';

export const SHARE_IN_PROGRESS_TEXT = 'Cannot share screen when another user is sharing';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: '48px',
      height: '48px',
      background: '#D0E8D6',
      '&:hover': {
        background: '#C0D8C6',
      },
    },
  })
);

export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const intl = useIntl();
  const screenShareParticipant = useScreenShareParticipant();
  const { toggleScreenShare } = useVideoContext();
  const disableScreenShareButton = Boolean(screenShareParticipant);
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = props.disabled || disableScreenShareButton || !isScreenShareSupported;

  let tooltipMessage = intl.formatMessage({ id: 'button.share_screen' });

  if (disableScreenShareButton) {
    tooltipMessage = intl.formatMessage({ id: 'screen_share_in_progress' });
  }

  if (!isScreenShareSupported) {
    tooltipMessage = intl.formatMessage({ id: 'screen_share_not_supported' });
  }

  return (
    <Tooltip
      title={tooltipMessage}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
    >
      <span>
        {/* The span element is needed because a disabled button will not emit hover events and we want to display
          a tooltip when screen sharing is disabled */}
        <IconButton className={classes.button} onClick={toggleScreenShare} disabled={isDisabled} data-cy-share-screen>
          <PresentToAllIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
}
