import React, { createContext, useContext, useReducer, useState } from 'react';
import { RecordingRules, RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import { useAuth0 } from '@auth0/auth0-react';
import { needsAuth, getHostParamFromUrl, getCmEndpoint } from '../utils'
import localeService from '../utils/locale-service';
import { useLocalStorageState } from '../hooks/useLocalStorageState/useLocalStorageState';

type User = {
  displayName?: string;
  photoURL?: string;
  determinedUserIdentity?: string;
}

type Call = {
  destination?: string;
  dateAndTime?: string;
  agentName?: string;
}

export interface StateContextType {
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  getToken(name: string, travelRequestUuid: string, isAuthenticated: boolean): Promise<{ token: string, determinedUserIdentity: string, roomType: RoomType }>;
  getTravelRequest(id: string): Promise<{ customerFullName: string, destination?: string, agentName?: string, dateAndTime?: string }>;
  user?: User | null;
  call?: Call | null;
  setUser(user: User): void;
  setCall(call: Call): void;
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<object>;
  locale: string;
  setLocale(locale: string): void;
  isGalleryViewActive: boolean;
  setIsGalleryViewActive: React.Dispatch<React.SetStateAction<boolean>>;
  maxGalleryViewParticipants: number;
  setMaxGalleryViewParticipants: React.Dispatch<React.SetStateAction<number>>;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isGalleryViewActive, setIsGalleryViewActive] = useLocalStorageState('gallery-view-active-key', true);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [roomType, setRoomType] = useState<RoomType>();
  const { getAccessTokenSilently } = useAuth0();
  const [user, setUser] = useState<User>();
  const [call, setCall] = useState<Call>();
  const [locale, setLocale] = useState(localeService.getDefaultLocale());
  const getAuth0Token = () => {
    return getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: process.env.REACT_APP_AUTH0_SCOPES,
    });
  }
  const [maxGalleryViewParticipants, setMaxGalleryViewParticipants] = useLocalStorageState(
    'max-gallery-participants-key',
    6
  );

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType,
    user,
    setUser,
    call,
    setCall,
    locale,
    setLocale,
    isGalleryViewActive,
    setIsGalleryViewActive,
    maxGalleryViewParticipants,
    setMaxGalleryViewParticipants,
  } as StateContextType;

  contextValue = {
    ...contextValue,
    getTravelRequest: async(id) => {
      const endpoint = `${getCmEndpoint()}/video/lead_info/${id}`
      return fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(async(response) => {
        if (response.status === 404) {
          throw new Error(`Travel request #${id} does not exist`)
        } else {
          const data = await response.json()
          return data
        }
      });
    },
    getToken: async (userIdentity: string, travelRequestUuid: string, isAuthenticated: boolean) => {
      let endpoint = `${getCmEndpoint()}/video/token`;
      const headers = new Headers({ 'content-type': 'application/json' })
      const payload = {
        user_identity: userIdentity,
        travel_request_uuid: travelRequestUuid,
      } as any;
      if (isAuthenticated || needsAuth()) {
        payload.auth = true;
        const host = getHostParamFromUrl();
        if (host) {
          payload.host = host;
        }
        const token = await getAuth0Token();
        headers.append('Authorization', `Bearer ${token}`)
      }
      return fetch(endpoint, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      }).then(res => res.json());
    },
    updateRecordingRules: async (roomSid: string, rules: object[]) => {
      const endpoint = `${getCmEndpoint()}/video/recording_rules`;
      const token = await getAuth0Token();

      return fetch(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ room_sid: roomSid, rules }),
        method: 'POST',
      })
      .then(async res => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const recordingError = new Error(
            jsonResponse.error?.message || 'There was an error updating recording rules'
          );
          recordingError.code = jsonResponse.error?.code;
          return Promise.reject(recordingError);
        }

        return jsonResponse;
      })
      .catch(err => setError(err));
    },
  };

  const getToken: StateContextType['getToken'] = (name, travelRequestUuid, isAuthenticated) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, travelRequestUuid, isAuthenticated)
      .then(res => {
        setRoomType(res.roomType);
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const updateRecordingRules: StateContextType['updateRecordingRules'] = (room_sid, rules) => {
    setIsFetching(true);
    return contextValue
      .updateRecordingRules(room_sid, rules)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const getTravelRequest: StateContextType['getTravelRequest'] = (uuid) => {
    setIsFetching(true);
    return contextValue
      .getTravelRequest(uuid)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken, updateRecordingRules, getTravelRequest }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
