import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAuth0 } from '@auth0/auth0-react';
import { trackPageView } from '@tourlane/tracking';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const [step, setStep] = useState(Steps.roomNameStep);
  const { isAuthenticated, isLoading: auth0IsLoading } = useAuth0();
  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (process.env.REACT_APP_DEPLOYED_TO && !auth0IsLoading && !isAuthenticated) {
      trackPageView({
        pageType: step === Steps.roomNameStep ? 'room-name' : 'device-selection',
      });
    }
  }, [isAuthenticated, auth0IsLoading, step]);


  useEffect(() => {
    // skip step where user can edit name when we are authenticated
    if (isAuthenticated && user?.displayName) {
      setStep(Steps.deviceSelectionStep);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStep(Steps.deviceSelectionStep);
  };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && <RoomNameScreen handleSubmit={handleSubmit} />}
      {user && user.displayName && step === Steps.deviceSelectionStep && <DeviceSelectionScreen name={user.displayName} />}
    </IntroContainer>
  );
}
